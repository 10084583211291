<template>
  <div>
    <router-link class="come-back" to="/actuals_topic">
      <div></div>
      Вернуться в раздел “Актуальные темы”
    </router-link>
    <div class="two-rows-wrapper" v-if="getCurrentTopic">
      <main>
        <div class="main-activity">
          <!-- дата -->
          <p class="main-activity__date">{{ getFormattedDate(getCurrentTopic.date) }}</p>
          <article>
            <header>
              <h1 v-if="getCurrentTopic.title">{{ getCurrentTopic.title }}</h1>
            </header>
            <figure>
              <img :src="getCurrentTopic.photo" :alt="getCurrentTopic.title">
            </figure>
            <div v-html="getCurrentTopic.text" class="main-activity__text ckEditor-html"></div>
            <div class="clearfix"></div>
          </article>
          <!-- новостные теги -->
          <div class="main-activity-tags">
            <p class="main-activity-tags__rubric" v-for="(tag, key) in getCurrentTopic.tags_list" :key="`tag${key}`">
              {{ tag }}</p>
          </div>
        </div>
        <div class="comment-form">
          <comment-form :source="'topic'" class="main-activity comment-form">
          </comment-form>
        </div>
        <div class="comment-form">
          <div class="main-activity comment-form">
            <h2>Комментарии</h2>
            <comment-new-bill v-for="item of getCurrentTopicComments" :card="item" :key="item.id"/>
          </div>
        </div>
        <!--<comment-form class="main-activity comment-form">
          <h2>Форма для отправки комментария</h2>
        </comment-form>-->
      </main>
      <aside class="two-rows-navigation">
      </aside>
    </div>
  </div>
</template>

<script>
import CommentForm from '@/components/activitiesSurveys/CommentForm'
import formatDate from '@/mixins/formatDate'
import CommentNewBill from '@/components/activitiesSurveys/CommentNewBill'

export default {
  name: 'CurrentTopic',
  mixins: [formatDate],
  components: {
    CommentForm,
    CommentNewBill
  },
  data () {
    return {
      // comments: [
      //   {
      //     id: 1,
      //     text: '«Для нас, крымчан, да и для всех россиян это очень знаковое событие.  Мост позволит республике окончательно воссоединиться с Россией.  Мост позволит республике окончательно воссоединиться с Россией.',
      //     date: '08.19.2020',
      //     author: {
      //       photo: 'image2.png',
      //       firstName: 'Иван',
      //       lastName: 'Абажер'
      //     }
      //   }
      // ]

    }
  },
  created () {
    this.$store.dispatch('getCurrentTopic', this.$route.params.id)
  },
  computed: {
    getCurrentTopic () {
      if (this.$store.getters.getCurrentTopic && this.$store.getters.getCurrentTopic.title) {
        document.title = this.$store.getters.getCurrentTopic.title
      }
      return this.$store.getters.getCurrentTopic
    },
    getCurrentTopicComments () {
      return this.$store.getters.getCurrentTopic.comments.map(item => {
        return {
          id: item.id,
          text: item.text,
          date: item.created,
          author: {
            photo: item.author.photo,
            firstName: item.author.firstName,
            lastName: item.author.lastName
          }
        }
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.ckEditor-html {
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }

    a {
      color: #0000ee !important;
      font-weight: bold;
    }

    a:visited {
      color: #ab00be !important;
    }
  }
}
.clearfix {
  clear: both;
}
  ::v-deep main {
    background: none;
    padding: 0;
    border-radius: 0;
  }

  .main-activity {
    font-size: 0.875rem;
    background: white;
    padding: 2.5rem 3.125rem;
    border-radius: 8px;

    &__date {
      color: #5E686C;
      opacity: 0.8;
      line-height: 1.57;
      margin-bottom: 1.25rem;
    }

    figure {
      img {
        border-radius: 8px;
        // max-width: 15rem;
        float: left;
        margin-right: 1.5625rem;
        width: 50%;
        max-width: 20rem;
        height: 11.25rem;
      }
    }

    article {
      text-align: left;
    }

    &__text {

      :nth-child(n) {
        margin: 0 0 1.375rem 0;
        line-height: 157%;
        color: rgba(23, 23, 23, 0.8);
      }

    }

    &-tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: .2rem;

      p {
        margin: 0.35rem 0.625rem 0.35rem 0;
      }

      &__rubric {
        background: #EEF4FA;
        border-radius: 3px;
        font-weight: 600;
        font-size: 0.8125rem;
        line-height: 123%;
        padding: .375rem .625rem;
        text-transform: uppercase;
        color: #003E78;
        opacity: 0.8;
      }
    }
  }

  .comment-form {
    margin-top: 3.125rem;

    > h2 {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 1.375rem;
      color: #1F3245;
    }
  }

  h1 {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.27;
    color: #1F3245;
    margin-bottom: .8125rem;
  }

  ::v-deep .two-rows-navigation {
    visibility: hidden;
  }

  /*стрелка назад*/

  .come-back {
    /*display: block;*/
    width: 84.37rem;
    margin: .875rem auto;
    font-size: .75rem;
    line-height: 1.38rem;
    display: flex;
    justify-content: flex-start;
    color: #246CB7;
    cursor: pointer;
  }

  .come-back div {
    position: relative;
    top: .625rem;
    width: .75rem;
    height: .0925rem;
    background-color: #337AB7;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    /*left:22em;*/
    display: block;
    border-radius: .820075px;
    margin-right: .4375rem;
  }

  .come-back div::after {
    content: '';
    position: absolute;
    width: .0825rem;
    height: .3125rem;
    top: -.23rem;
    left: .11rem;
    background-color: #337AB7;
    transform: rotate(-135deg);
    border-radius: .820075px;
  }

  .come-back div::before {
    content: '';
    position: absolute;
    width: .0825rem;
    height: .3125rem;
    /*top: 11px;*/
    left: .11rem;
    background-color: #337AB7;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    transform: rotate(135deg);
    border-radius: .820075px;
  }

  @media screen and (max-width: 768px) {
    .main-activity {
      padding: 2.5rem 1rem;
    }
    .come-back {
      width: 90%;
    }
  }

  @media screen and (max-width: 420px) {
    .main-activity figure img {
      max-width: 100%;
      width: 100%;
      float: unset;
      margin-right: 0;
      margin-bottom: 2.125rem;
    }
  }
</style>
